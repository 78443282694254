
import { Component, Vue, Watch } from 'vue-property-decorator';
import FooterComponent from '@/components/FooterComponent.vue';

@Component({
  components: {
    FooterComponent
  }
})
export default class Register extends Vue {
  userId: string = '';
  password: string = '';
  rememberMe = false;
  isShowTimeout = false;
  isError = false;
  csrfToken = '';

  /** mounted */
  mounted(): void {
    // URL パラメータを取得 timeoutの場合はタイムアウトを表示
    // eslint-disable-next-line no-restricted-globals
    const pair: string[] = window.location.search.substring(1).split('&');
    for (let i = 0; pair[i]; i += 1) {
      if (pair[i] === 'timeout') {
        this.isShowTimeout = true;
      }
      if (pair[i] === 'error') {
        this.isError = true;
      }
    }
    // CSRFトークンをポストパラメータとしてフォームにセット
    this.csrfToken = this.getCsrfTokenByCookie();
  }
  /**
   * CSRFトークンをクッキーから取得する
   */
  getCsrfTokenByCookie(): string {
    // eslint-disable-next-line no-useless-escape
    const cookie: string = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    return cookie;
  }

  @Watch('userId')
  onUserChange():void {
    if (this.userId.length >= 3) {
      // 3桁入力したらパスワードへフォーカス移動
      const pwd:any = this.$refs.passwordInput;
      if (pwd) pwd.$el.focus();
    }
  }
}
